import React, { Fragment } from "react"
import "./style.scss"
import ProductItem from "../ProductItem"
import AccessoryItem from "../AccessoryItem"
import GlassesPromo from "../GlassesPromo"

const GlassesList = ({ glasses, type, color, gender, isKids }) => (
  <>
    <CustomList type={type} colsNumber={4} items={glasses} color={color} gender={gender} isKids={isKids} />
    <CustomList type={type} colsNumber={3} items={glasses} color={color} gender={gender} isKids={isKids} />
    <CustomList type={type} colsNumber={2} items={glasses} color={color} gender={gender} isKids={isKids} />
    <CustomList type={type} colsNumber={1} items={glasses} color={color} gender={gender} isKids={isKids} />
  </>
)

export const CustomList = ({ items, colsNumber, type, color, gender, isKids }) => {
  return (
    <div className="products-list" data-cols={colsNumber}>
      {items.map((product, index) => {
        const number = index + 1
        const isPromo = number % (colsNumber === 4 ? (isKids ? 5 : 6) : 4)
        const currentRow = Math.trunc(number / (colsNumber === 4 ? 3 : 2))
        const isOdd = (number / (colsNumber === 4 ? 6 : 4)) % 2

        if (!isPromo) {
          return isOdd || colsNumber < 3 ? (
            <Fragment key={product.shopifyProductId}>
              {type === "accessory" ? 
                <AccessoryItem type={type} product={product} key={index} position={index+1} /> 
                : <ProductItem type={type} product={product} color={color} gender={gender} position={index+1} />
              }
              {index <= 10 && <GlassesPromo isOdd={isOdd} type={type === "accessory" ? "sunglasses" : type} />}
            </Fragment>
          ) : (
            <Fragment key={product.shopifyProductId}>
              {type === "accessory" ? 
                <AccessoryItem type={type} product={product} key={index} position={index+1} /> 
                : <ProductItem type={type} product={product} color={color} gender={gender} position={index+1} />
              }
              <GlassesPromo currentRow={currentRow} isOdd={isOdd} type={type === "accessory" ? "sunglasses" : type} />
            </Fragment>
          )
        }

        return ( 
          <>
            { type === "accessory" ? 
              <AccessoryItem type={type} product={product} key={index} position={index+1} /> 
              : <ProductItem
                  type={type}
                  product={product}
                  key={product.shopifyProductId}
                  color={color}
                  gender={gender}
                  position={index+1}
                />
            }
          </>
        )
      })}
    </div>
  )
}

export default GlassesList
