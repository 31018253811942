import React from "react"
import WebpImage from "../WebpImage"

import "./style.scss"

const ProductBanner = ({title, right_side_text, list_items, optional_image, color,optional_image_alt_text }) => {
  const optionalImage = `Pages/PCP/${optional_image}`

  return (
    <div className="product-banner">
      <div className="optional-image">
        <WebpImage fileName={optionalImage} alt={optional_image_alt_text} />
      </div>
      <div className="optional-text">
        <div className="title">
          <h2 className="text h3">{title}</h2>
        </div>
        <div className="description">
          <h5 className="subtitle text h5"
              dangerouslySetInnerHTML={{ __html: right_side_text }}
          />
          {list_items && (
            <ol className="features-list">
              {list_items.map((item, index) => {
                return <li className="list-item" key={index}>
                  <div className="number text h8 neutral white" data-color={color}>{index +1 }</div>
                  <p className="text neue h7"
                      dangerouslySetInnerHTML={{ __html: item }}
                  />
                </li>
              })}
            </ol>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductBanner
