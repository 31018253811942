import React, { useEffect, useState } from "react"
import NewButton from "../NewButton"
import "./style.scss"

const GlassesPromo = ({ currentRow, isOdd, type }) => {
  const [linkTo, setLinkTo] = useState("/sunglasses")
  const [promoHeader, setPromoHeader] = useState(`Starting at $59.99`)
  const [promoBody, setPromoBody] = useState(
    "The Hubble frames you love with polarized lenses that block 99% of UVA/UVB rays."
  )
  const [buttonText, setButtonText] = useState("Shop Sunglasses")

  useEffect(() => {
    if (type === "glasses") {
      setPromoHeader(`Prescription Sunglasses Starting at $59.99`)
      setPromoBody(
        "The Hubble frames you love with polarized lenses that block 99% of UVA/UVB rays."
      )
      setButtonText("Shop Now")
      if (isOdd) {
        setButtonText("Shop Now")
        setLinkTo("/contact-lenses/hubble")
      }
    } else if (type === "sunglasses" && isOdd) {
      setButtonText("Shop Now")
      setLinkTo("/contact-lenses/hubble")
    } else {
      setPromoHeader(`Look Good - See Better`)
      setPromoBody(
        "High-quality lenses. Stylish, on-trend frames. The look you want for less. Starting at just $49.99."
      )
      setButtonText("Shop Now")
      setLinkTo("/glasses")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <div
      className="glasses-promo"
      style={{ gridRow: currentRow }}
      data-odd={!!isOdd}
      data-type={type}
    >
      <div className={`text-block ${isOdd && "blue"}`}>
        <div className="text-container">
          {!!isOdd && (
            <h5 className="title text h5 white">
              Get contact lenses. Right to your door. Right when you need them.
            </h5>
          )}
          {!!!isOdd && <h5 className="title text h5 white">{promoHeader}</h5>}

          {!!isOdd && (
            <p className="description text h7 neue white">
              Easily alternate between glasses and lenses with our no-fuss subscription and home delivery.
            </p>
          )}
          {!!!isOdd && (
            <p className="description text h7 neue white">{promoBody}</p>
          )}
        </div>

        <NewButton label={`${buttonText}`} to={linkTo} />

      </div>
      <div className="image-block" />
    </div>
  )
}

export default GlassesPromo
